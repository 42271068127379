import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StoriesTaxVerse, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'insights';

export default function() {
  const { contentfulInsightsPage, allContentfulPost } = useStaticQuery(graphql`
    {
      contentfulInsightsPage(slug: { eq: "insights" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroCtaCopy
        heroDescription {
          json
        }
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        categories {
          id
          name
          expandCta
        }
        spacerNavigator {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
      allContentfulPost(sort: { order: [ASC, DESC], fields: [pinned, createdAt] }) {
        group(field: category___id) {
          fieldValue
          totalCount
          nodes {
            pinned
            createdAt
            headline
            ctaText
            slug
            redirectUrl
            internal {
              type
            }
            image {
              ...ImageSharpStoryCard
            }
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulInsightsPage.metaTitle,
      description: contentfulInsightsPage.metaDescription,
      image:
        contentfulInsightsPage.image &&
        contentfulInsightsPage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulInsightsPage.heroHeadline,
      body: contentfulInsightsPage.heroDescription,
      buttonHeadline: contentfulInsightsPage.heroCtaCopy,
      buttonText: contentfulInsightsPage.heroCtaText,
      buttonUrl:
        contentfulInsightsPage.heroCtaLink &&
        linkResolver({
          type: contentfulInsightsPage.heroCtaLink[0].internal.type,
          slug: contentfulInsightsPage.heroCtaLink[0].slug,
          postPrefix,
        }),
      image: contentfulInsightsPage.heroImage,
      textBackground: 'dark-2',
      ctaBackground: 'accent-1',
    },
    categories: contentfulInsightsPage.categories.map(({ id, name, expandCta }) => ({
      headline: contentfulInsightsPage.categories.length > 1 && name,
      buttonText: expandCta,
      cards: allContentfulPost.group
        .filter(({ fieldValue }) => fieldValue === id)
        .pop()
        .nodes.map((card) => ({
          headline: card.headline,
          linkText: card.ctaText,
          linkUrl: linkResolver({
            type: card.internal.type,
            slug: card.redirectUrl || card.slug,
            postPrefix,
          }),
          image: card.image,
        })),
    })),
    cta: {
      headline: contentfulInsightsPage.spacerNavigator[0].headline,
      buttonText: contentfulInsightsPage.spacerNavigator[0].ctaText,
      buttonType:
        contentfulInsightsPage.spacerNavigator[0].ctaLink &&
        contentfulInsightsPage.spacerNavigator[0].ctaLink[0].internal.type,
      buttonUrl:
        contentfulInsightsPage.spacerNavigator[0].ctaLink &&
        linkResolver({
          type: contentfulInsightsPage.spacerNavigator[0].ctaLink[0].internal.type,
          slug:
            contentfulInsightsPage.spacerNavigator[0].ctaLink[0].redirectUrl ||
            contentfulInsightsPage.spacerNavigator[0].ctaLink[0].slug,
          postPrefix,
        }),
      primary: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <StoriesTaxVerse doc={doc} Link={Link} />
    </Layout>
  );
}
